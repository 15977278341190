/*
 * @Author: yuanxf
 * @Email: yuanxf@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: yuanxf
 * @Last Modified time: 2024-11-04 15:42:09
 * @Description: Description
 * @Route: /hxcm/expert-review-management
 */

import View from '@/components/view';

const preliminaryReviewProgress = () => import(/* webpackChunkName: "preliminary-review-progress" */ '@/pages/hxcm/expert-review-management/preliminary-review-progress/index');
const reviewEvaluationProgress = () => import(/* webpackChunkName: "review-evaluation-progress" */ '@/pages/hxcm/expert-review-management/review-evaluation-progress/index');

const routes = [
    {
        path: 'expert-review-management',
        component: View,
        meta: {
            title: '评审专家管理'
        },
        children: [
            {
                path: 'preliminary-review-progress',
                component: preliminaryReviewProgress,
                meta: {
                    title: '初审评审进度'
                }
            },
            {
                path: 'review-evaluation-progress',
                component: reviewEvaluationProgress,
                meta: {
                    title: '复审评审进度'
                }
            }
        ]
    }
];

export default routes;
